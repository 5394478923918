$color-active: #205527;
$color-hover: #008200;
$color-normal: #62B062;
$color-rojo: #e10613;
$color-naranja: #f38f04;
$color-azul: #2D6DB4;
$color-texto: #54555B;

// Breakpoints
$xs-0: 0;
$sm-600: 600px;
$md-900: 900px;
$lg-1280: 1280px;
$xl-1536: 1536px;

// Espacios
.espacio-10 {
  height: 10px;
}
.espacio-30 {
  height: 30px;
}
.espacio-50 {
  height: 50px;
}
.espacio-100 {
  height: 100px;
}
.espacio-200 {
  height: 200px;
}
.espacio-300 {
  height: 300px;
}
.espacio-400 {
  height: 400px;
}
.espacio-500 {
  height: 500px;
}