@import "../../resources/scss/variables";

@font-face {
  font-family: "Montserrat Regular";
  src: url("../../resources/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("../../resources/fonts/Montserrat-Bold.ttf");
}

body {
  margin: 0;
  font-family: "Montserrat Regular";
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color: #fff;
  background-color: #F4F4F4 !important;
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* movimiento arriba abajo */
.up-to-down {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}

@keyframes play {
  100% {
    background-position: 0 -7110px;
  }
}

#root {
  overflow: hidden;
}

#root::after {
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -1;
  background-repeat: no-repeat;
  background-position-y: 100%;
  background-position-x: center;
  background-size: cover;
  background-position: center;
  background-color: #F4F4F4;
  // background-image: url(../../resources/images/bg.svg);
}

.btn-cerrar-popup {
  color: #fff;
  float: right;
  background: #2c6db4;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 10px;
}

$bg: #f3f8fa;
$white: #fff;
$black: $color-hover;

@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65, 0, .076, 1)) {
  transition: $property $duration $ease;
}
button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;

  &.learn-more {
    width: 14rem;
    height: auto;

    .circle {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
      position: relative;
      display: block;
      margin: 0;
      width: 3rem;
      height: 3rem;
      background: $black;
      border-radius: 1.625rem;

      .icon {
        @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        background: $white;

        &.arrow {
          @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
          left: 0.625rem;
          width: 1.125rem;
          height: 0.125rem;
          background: none;

          &::before {
            position: absolute;
            content: '';
            top: -0.25rem;
            right: 0.0625rem;
            width: 0.625rem;
            height: 0.625rem;
            border-top: 0.125rem solid #fff;
            border-right: 0.125rem solid #fff;
            transform: rotate(45deg);
          }
        }
      }
    }

    .button-text {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0.75rem 0;
      margin: 0 0 0 1.85rem;
      color: $black;
      font-weight: 700;
      line-height: 1.6;
      text-align: center;
      text-transform: uppercase;
    }
  }

  &:hover {
    .circle {
      width: 100%;

      .icon {
        &.arrow {
          background: $white;
          transform: translate(1rem, 0);
        }
      }
    }

    .button-text {
      color: $white;
    }
  }
}

// .btn {
//   line-height: 50px !important;
//   height: 50px !important;
//   text-align: center !important;
//   width: 170px !important;
//   cursor: pointer !important;
//   background-color: $color-azul;
// }

p,
h1,
h2,
h3 {
  font-family: "Montserrat Regular";
}

.full {
  width: 100%;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.bold {
  font-weight: bold !important;
}

.cursor {
  cursor: pointer;
}

.padre {
  display: table;

  & .hijos {
    display: table-cell;
    vertical-align: middle;
  }
}

.padres {
  display: table;

  & .hijoss {
    display: center;
    vertical-align: middle;
  }
}

.flex {
  display: flex;
}

.contenedor-streaming {
  width: 100%;
  text-align: center;
  // margin-bottom: 60px;

  & .estilo-streaming {
    width: 100%;
    height: 450px;
  }
}

.titulos {
  font-size: 36px;
  color: $color-hover;
  font-weight: 700;
  font-family: "Montserrat Bold";
}

.blanco {
  color: #fff !important;
}

/* width scroll */
::-webkit-scrollbar {
  width: 0px;
}

.mb30 {
  margin-bottom: 30px;
}

.mt30 {
  margin-top: 30px;
}

.padding {
  padding: 15px;
}

.regresar {
  cursor: pointer;
  color: $color-hover;
  font-family: "Montserrat Bold";
}

.disable {
  filter: grayscale(1) !important;
}

// celular
@media screen and (max-width: 425px) and (orientation: portrait) {
  .titulos {
    font-size: 22px;
  }

  .contenedor-streaming {
    & .estilo-streaming {
      width: 100%;
      height: auto;
    }
  }
}

@media screen and (max-width: 880px) and (orientation: landscape) {
  .titulos {
    font-size: 22px;
  }

  .contenedor-streaming {
    & .estilo-streaming {
      width: 100%;
      height: auto;
    }
  }
}

// tablet
@media screen and (min-width: 881px) and (max-width: 1440px) and (orientation: landscape) {
  .contenedor-streaming {
    & .estilo-streaming {
      width: 100%;
      height: auto;
    }
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background: #fff !important;
  }
}